<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h4>Search Criteria</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <b-form-group label="Search Title" label-size="lg" label-for="qdName" description="Please enter a unique Title for this Search">
                    <b-form-input id="qdName" v-model="qTitle" type="text" 
                        :maxlength="limits.qdName" 
                        @change.native="updateData('title', $event)"
                        required placeholder="Search Title" size="lg"></b-form-input>
                </b-form-group>
            </div>
            <div class="col-6 alerts-selectors">
                <div class="form-label d-block col-form-label-lg mb-4">Alerts (Weekly Email)</div>
                <div class="form-check form-check-inline">
                    <input @change="updateData('alerts', searchAlerts)" type="checkbox" class="form-check-input" value="project" id="alertProject" v-model="searchAlerts" >
                    <label class="form-check-label" for="alertProject" v-b-tooltip.hover="alertTooltip('Project')">Project</label>
                </div>
                <div class="form-check form-check-inline">
                    <input @change="updateData('alerts', searchAlerts)" type="checkbox" class="form-check-input" value="publication" id="alertPublication" v-model="searchAlerts" >
                    <label class="form-check-label" for="alertPublication" v-b-tooltip.hover="alertTooltip('Publication')">Publication</label>
                </div>
                <div class="form-check form-check-inline">
                    <input @change="updateData('alerts', searchAlerts)" type="checkbox" class="form-check-input" value="news" id="alertNews" v-model="searchAlerts" >
                    <label class="form-check-label" for="alertNews" v-b-tooltip.hover="alertTooltip('News')">News</label>
                </div>

            </div>

            <div class="col-12"> 
                <b-form-group label="Notes" label-size="lg" label-for="qdNotes">
                    <b-form-textarea id="qdNotes" v-model="qNotes" type="text" 
                        :maxlength="limits.qdNotes" 
                        @keyup="updateData('notes', qNotes)"
                        required placeholder="Search Notes" size="lg"></b-form-textarea>
                        <small>Optional Notes for this Search ({{ remainingChars }} characters remaining)</small>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions, useStore } from 'vuex';
    import { toRaw } from "vue";

    export default {
        data() {
            return {
                alertOptions: [
                    {text: 'Project', value: "project" },
                    {text: 'Publication', value: "publication" },
                    {text: 'News', value: "news" }
                ],
                limits: {
                    qdName: 200,
                    qdNotes: 1000
                },
                remainingChars: 1000,
                searchAlerts: []
            }
        },
        props: {
            title: { type: String },
            notes: { type: String},
            alerts: { Stype: Array},
            id: { type: Number}
        },
        computed: {
            ...mapGetters(['currentQueryDetails'])
        },
        setup(props, context) {
            const store = useStore();
            let qTitle = '',
                qNotes ='',
                qAlerts = [],
                qId = null,
                thisSelectedAlerts = [];

            let currentQueryDetails = store.state.currentQueryDetails;

            if(currentQueryDetails != null)    {
                qTitle = currentQueryDetails.title || '';
                qNotes = currentQueryDetails.notes ? currentQueryDetails.notes.trim() : "";
                qAlerts = currentQueryDetails.alerts || [];
                qId= currentQueryDetails.id || 0;
                thisSelectedAlerts = currentQueryDetails.alerts || [];
            }
            return {
                qTitle,
                qNotes,
                qAlerts,
                qId,
                thisSelectedAlerts
            }
        },
        mounted () {
            if (this.qNotes) {
                this.remainingChars = this.limits.qdNotes - this.qNotes.length;
            }
        },
        methods: {
            ...mapActions([
                'setCurrentQueryDetailsAction',
            ]),
            updateData (field, data) {
                let queryData = copyData(this.currentQueryDetails);

                if (!queryData && queryData == null) {
                    queryData = {
                        title: "",
                        notes: "",
                        alerts: [],
                        id: 0
                    }
                }
                queryData[field] = toRaw(data);
                if (field === 'notes') {
                    this.remainingChars = this.limits.qdNotes - data.length;
                }
                this.setCurrentQueryDetailsAction(queryData);
            },
            alertTooltip(a) {
                return `Select to Receive Weekly ${a} Alerts`;
            }
        }
    }
</script>

<style lang="scss" scoped>
   
</style>