<template>
    <!-- <div class="search-tile-outer"> -->
        <!--<div class="search-tile" ref="tile" tabindex="0" @click="clickTile()" @keyup.enter="clickTile()">-->
        <div class="tile" ref="tile">
            <div class="search-tile-inner" ref="tileInner">
                <div class="field-title" ref="fieldTitle">
                    <!-- <a :href="refLink" v-if="refLink" target="_blank" title="Access legacy version here">{{name}}</a> -->
                    <!-- <template v-else>{{name}}</template> -->
                    {{name}}
                    <span v-if="subTitle" class="sub-title">{{subTitle}}</span>
                </div>
                <div class="tile-content-wrapper">
                    <div v-if="loading" class="loading"><div class="loader-bg"></div><span class="loader">Loading...</span></div>
                    <div class="tile-chart" ref="eChartsDOM" v-if="!serviceError"></div>
                    <div class="field-error-message error-large" v-if="serviceError">
                        Unable to load chart data
                        <div class="error-code">
                        {{serviceError}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>

<script>
import * as echarts from 'echarts';
import 'echarts/lib/component/tooltip';
import {mapGetters, mapActions} from 'vuex';
import globalMixins from '@/globalMixins';

export default {
    name: 'ChartTile',
    components: {
    },
    mixins: [globalMixins],
    computed: {
        tileComponent () {
            return this.name.replace(/\s/g,'');
        },
        tileClass () {
            return this.name.replace(/\s/g,'-').toLowerCase();
        },
        ...mapGetters([
          'homePageChartData',
          'externalURLs',
          'homeChartSearchId'
        ])
    },
    props: {
        name: {
            required: true
        },
        subTitle: {
            required: false
        },
        description: {
            required: false
        }
    },
    data: function () {
        return {
            init: false,
            isActive: this.active,
            loading: true,
            width: 0,
            height: 0,
            minHeight: 0,
            baseMinHeight: 0,
            smallTilePercent: .25,
            largeTilePercent: .75,
            margin: 15,
            oppositeX: 0,
            newLastActiveY: 0,
            top: 0,
            left: 0,
            expanded: false,
            defaultDuration: 301,
            eChart: null,
            animate: true,
            resizeTimeout: null,
            tooltipHoverIC: null,
            serviceError: null
        };
    },
    mounted () {
        this.eChartsInit();
    },
    methods: {
        ...mapActions([
            'setSearchFacetCriteria',
            'setSearchFacetData',
        //    'setPublicationsFacetData',
        //    'setPublicationsFacetCriteria',
            'updateSearchResultsFacets',
            'setSearchMeta',
            'setSearchCriteria',
            'setSearchResults',
            'setHomePageChartData',
            'setHomeChartSearchId'
        ]),
        eChartsInit () {
            let searchParams = {
                    facet_filters: {
                        fiscal_years: [],
                        org_names: [],
                        agencies: [],
                        states: [],
                        countries: [],
                        principal_investigators: []
                    },
                    summarized_by: "Agency",
                    plotted_by: "count",
                    limit_count: 100,
                    criteria: {
                        fiscal_years: "",
                        is_agency_admin: true,
                        agencies: "NIH"
                    },
                    home_page_chart: true,
                    search_id: null
                },
                processClick = (event, name) => {
                    if (event.ctrlKey || event.metaKey) {
                        let routeData = this.$router.resolve({
                            path: '/search/' + this.homeChartSearchId + '/projects',
                            query: {
                                agencies: name
                            }
                        });
                        window.open(routeData.href, '_blank');
                    } else {
                        this.setSearchMeta(null);
                        this.setSearchResults(null);
                        this.setSearchCriteria(null);
                        this.setEditSearchCriteria(null);
                        this.setSearchFacetData(null);
                        this.setSearchType('Advanced');
                        this.clearSearchResults({resetFacets: true});

                        window.firstLoadFacets = {
                            agencies: [name]
                        };

                        this.$nextTick(() => {
                            this.$router.push({
                                path: '/search/' + this.homeChartSearchId + '/projects',
                                query: {
                                    agencies: name
                                }
                            });
                        });
                    }
                };

            this.eChart = echarts.init(this.$refs.eChartsDOM);



            // this.eChart.on('click', (params) => {
            //     if (params && params.name) {
            //         processClick(params.name);
            //     }
            // });

            this.$refs.eChartsDOM.addEventListener('click', (event) => {
                if (this.tooltipHoverIC) {
                    processClick(event, this.tooltipHoverIC);
                }
            });

            window.addEventListener('resize', () => {
                this.resizeChart();
            });

            if (!this.homePageChartData) {
                window.getSearchGraphData(searchParams, (data) => {
                    if (data) {
                        this.setHomeChartSearchId(data.meta.search_id);
                        // this.loading = false;
                        this.parseData(data, this.eChartsBuild);
                    }
                }, '/services/Chart/');
            } else {
                // this.loading = false;
                this.eChartsBuild(this.homePageChartData);
            }

            this.init = true;
        },
        parseData (data, callback) {
            if (!data.results) {
                  this.serviceError = this.externalURLs.reporter_project_search_error_message.error_message;
                  this.loading = false;

                return false;
            }

            let xAxis = [],
                counts = [],
                color = [
                    '#084081',
                    '#2283ba',
                    '#67c1cb',
                    '#b4e2ba',
                    '#faecaf',
                    '#f7bb6c',
                    '#f78c55',
                    '#C54A53'
                ];

            data.results.sort((a, b) => {
                return (a.group_by > b.group_by) ? 1 : -1;
            });

            for (let i = 0; i < data.results.length; i++) {
                // temp fix to parse out NIH total from chart data
                if (data.results[i].group_by !== 'NIH') {
                    xAxis.push(data.results[i].group_by);
                    counts.push({
                        value: data.results[i].project_count,
                        itemStyle: {
                            color: color[i % color.length]
                        }
                    });
                }
            }

            this.setHomePageChartData({
                xAxis: xAxis,
                counts: counts
            });

            callback(this.homePageChartData);
        },
        resizeChart () {
            if (this.animate) {
                this.eChart.setOption({
                    animation: false
                });
            }

            try {
                this.eChart.resize();
            } catch (er) {
                
            }

            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }

            this.resizeTimeout = setTimeout(() => {
                this.animate = true;

                this.eChart.setOption({
                    animation: true
                });
            }, 300);
        },
        eChartsBuild (data) {
            var option = {
                grid: {
                    top: 10,
                    right: 5,
                    bottom: 52,
                    left: 65
                },
                tooltip: {
                    confine: true,
                    trigger: 'item',
                    // triggerOn: 'click',
                    axisPointer: {
                        shadowStyle: {
                            color: 'rgba(8, 130, 220, 0.1)'
                        },
                        type: 'shadow',
                        z: '0',
                    },
                    formatter: (params) => {
                        this.tooltipHoverIC = params.name;

                        return '<strong>Active Projects</strong><br>' + params.marker + params.name + ': ' + window.addCommas(params.value);
                    }
                },
                xAxis: {
                    type: 'category',
                    nameLocation: 'center',
                    axisLabel: {
                        rotate: 90
                    },
                    data: data.xAxis
                    // data: ["NCI", "NIAID", "NIGMS", "NHLBI", "NINDS", "NIDDK", "NIA", "NIMH", "NICHD", "NIDA", "NEI", "NIAMS", "NIEHS", "NIDCD", "NIAAA", "NIBIB", "NIDCR", "OD", "NHGRI", "NCATS", "NIMHD", "NINR", "FIC", "NCCIH", "NLM", "CLC", "CIT"]
                    // data: ["CIT", "CLC", "FIC", "NCATS", "NCCIH", "NCI", "NEI", "NHGRI", "NHLBI", "NIA", "NIAAA", "NIAID", "NIAMS", "NIBIB", "NICHD", "NIDA", "NIDCD", "NIDCR", "NIDDK", "NIEHS", "NIGMS", "NIMH", "NIMHD", "NINDS", "NINR", "NLM", "OD"]
                },
                yAxis: {
                    type: 'value',
                    name: 'Number of Active Projects',
                    nameLocation: 'center',
                    nameGap: 50,
                    nameTextStyle: {
                        fontWeight: 'bold',
                        fontFamily: 'sans-serif'
                    },
                },
                series: [{
                    data: data.counts,
                    // data: [9555, 8296, 7738, 6988, 5066, 4877, 4764, 3953, 3533, 2979, 1964, 1685, 1499, 1405, 1314, 1157, 1137, 753, 695, 588, 542, 434, 401, 376, 259, 138, 12],
                    // data: [
                    //     {
                    //         "value": 12,
                    //         "itemStyle": {
                    //         "color": "#084081"
                    //         }
                    //     },
                    //     {
                    //         "value": 138,
                    //         "itemStyle": {
                    //         "color": "#2283ba"
                    //         }
                    //     },
                    //     {
                    //         "value": 401,
                    //         "itemStyle": {
                    //         "color": "#67c1cb"
                    //         }
                    //     },
                    //     {
                    //         "value": 588,
                    //         "itemStyle": {
                    //         "color": "#b4e2ba"
                    //         }
                    //     },
                    //     {
                    //         "value": 376,
                    //         "itemStyle": {
                    //         "color": "#faecaf"
                    //         }
                    //     },
                    //     {
                    //         "value": 9555,
                    //         "itemStyle": {
                    //         "color": "#f7bb6c"
                    //         }
                    //     },
                    //     {
                    //         "value": 1964,
                    //         "itemStyle": {
                    //         "color": "#f78c55"
                    //         }
                    //     },
                    //     {
                    //         "value": 695,
                    //         "itemStyle": {
                    //         "color": "#C54A53"
                    //         }
                    //     },
                    //     {
                    //         "value": 6988,
                    //         "itemStyle": {
                    //         "color": "#084081"
                    //         }
                    //     },
                    //     {
                    //         "value": 4764,
                    //         "itemStyle": {
                    //         "color": "#2283ba"
                    //         }
                    //     },
                    //     {
                    //         "value": 1314,
                    //         "itemStyle": {
                    //         "color": "#67c1cb"
                    //         }
                    //     },
                    //     {
                    //         "value": 8296,
                    //         "itemStyle": {
                    //         "color": "#b4e2ba"
                    //         }
                    //     },
                    //     {
                    //         "value": 1685,
                    //         "itemStyle": {
                    //         "color": "#faecaf"
                    //         }
                    //     },
                    //     {
                    //         "value": 1157,
                    //         "itemStyle": {
                    //         "color": "#f7bb6c"
                    //         }
                    //     },
                    //     {
                    //         "value": 3533,
                    //         "itemStyle": {
                    //         "color": "#f78c55"
                    //         }
                    //     },
                    //     {
                    //         "value": 2979,
                    //         "itemStyle": {
                    //         "color": "#C54A53"
                    //         }
                    //     },
                    //     {
                    //         "value": 1405,
                    //         "itemStyle": {
                    //         "color": "#084081"
                    //         }
                    //     },
                    //     {
                    //         "value": 1137,
                    //         "itemStyle": {
                    //         "color": "#2283ba"
                    //         }
                    //     },
                    //     {
                    //         "value": 4877,
                    //         "itemStyle": {
                    //         "color": "#67c1cb"
                    //         }
                    //     },
                    //     {
                    //         "value": 1499,
                    //         "itemStyle": {
                    //         "color": "#b4e2ba"
                    //         }
                    //     },
                    //     {
                    //         "value": 7738,
                    //         "itemStyle": {
                    //         "color": "#faecaf"
                    //         }
                    //     },
                    //     {
                    //         "value": 3953,
                    //         "itemStyle": {
                    //         "color": "#f7bb6c"
                    //         }
                    //     },
                    //     {
                    //         "value": 542,
                    //         "itemStyle": {
                    //         "color": "#f78c55"
                    //         }
                    //     },
                    //     {
                    //         "value": 5066,
                    //         "itemStyle": {
                    //         "color": "#C54A53"
                    //         }
                    //     },
                    //     {
                    //         "value": 434,
                    //         "itemStyle": {
                    //         "color": "#084081"
                    //         }
                    //     },
                    //     {
                    //         "value": 259,
                    //         "itemStyle": {
                    //         "color": "#2283ba"
                    //         }
                    //     },
                    //     {
                    //         "value": 753,
                    //         "itemStyle": {
                    //         "color": "#67c1cb"
                    //         }
                    //     }
                    // ],
                    type: 'bar'
                }]
            };

            this.loading = false;

            this.eChart.setOption(option);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.tile {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $blue-charts;
    }
}

.sub-title {
    display: block;
    margin-bottom: -18px;
    font-size: 13px;
    font-weight: 700;
    color: #757575;
}

.search-tile-outer {
    position: absolute;
    padding: $tile-spacing;

    @media screen and (max-width: $screen-sm) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &.animate {
        transition: 0.3s;

        // &,
        // &.search-tile {
        //     transition: 0.3s;
        //     // transition: 0s;
        // }
    }
    @media screen and (max-width: $screen-md-max) {
        // override fancy positioning on mobile
        position: relative;
        top: auto !important;
        left: auto !important;
        bottom: auto !important;
        right: auto !important;
        width: 100% !important;
        height: auto !important;
    }
}

.search-tile {
    $tile-padding: 24px;

    padding: $tile-padding * 0.9 $tile-padding;
    background-color: #fff;
    border-radius: $input-radius;
    box-shadow: 0 2px 8px 0px rgba(0,0,0,0.25);
    height: 100%;
    transition: 0.3s;
    outline: none;
    overflow: hidden;

    @media screen and (max-width: $screen-sm) {
        padding-left: 10px;
        padding-right: 10px;
    }

    &:hover:not(.active),
    &:focus:not(.active) {
        box-shadow: 0 4px 16px 0px rgba(0,0,0,0.45);
    }

    &.active {
        height: auto;
        transition: 0s;
        overflow: visible;

        .tile-content {
            position: relative;
        }

        .tile-description {
            position: absolute;
        }
    }
}

.field-title {
    margin-bottom: 1em;
    color: #212529;
    // &:after {
    //     background-color: $blue;
    // }

    a {
        &:hover, &:active, &:focus {
            text-decoration: none;
        }
    }
}

.tile-content-wrapper {
    position: relative;
}

.tile-content {
    position: absolute;
    top: 0;
    left: 0;
    // margin-top: 3px;
    margin-left: -15px;
    padding-left: 0;
    padding-right: 0;
    width: calc(100% + #{$grid-gutter-width});
    transition: 0.3s;
}

.tile-description {
    // margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
}

.hide-content {
    opacity: 0;
    pointer-events: none;
}

.legacy-link {
    display: inline-block;
    margin: 10px 0 20px;
    padding: 5px 8px 5px 12px;
    background-color: $grey-lighter;
    border-radius: 15px;
    color: #333;
    font-weight: 700;

    @media screen and (max-width: 1250px) and (min-width: 1200px) {
        font-size: 13px;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: #E7F1FB;
        color: $blue;

        .arrow-right {
            fill: $blue;
        }
    }

    .arrow-right {
        position: relative;
        margin-left: 5px;
        top: -1px;
        width: 15px;
        fill: #333;
    }
}


</style>
