<template>
    <div :class="loading ? 'loading' : ''">
        <!-- Mini Search Form -->
        <div class="search-form" v-if="!showAllFields">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="FiscalYear"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="PI"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="Organization" />
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="AgencyIC"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="ProjectNumber"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 search-buttons">
                    <button
                        type="button"
                        class="btn btn-lg btn-secondary btn-clear"
                        @click.stop="reset()"
                        v-if="!refpage"
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        class="btn btn-lg btn-secondary btn-clear"
                        @click.stop="cancel()"
                        v-if="refpage"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        class="btn btn-lg btn-primary btn-search"
                        @click.stop="search()"
                        :disabled="loading"
                    >
                        Search
                        <div class="loader" v-if="loading"></div>
                    </button>
                    <field-error
                        v-if="serviceError"
                        :message="serviceErrorMessage"
                        :errorCode="serviceError"
                    />
                </div>
            </div>
        </div>

        <!-- Full Search Form -->
        <div class="search-form" v-if="showAllFields">
            <div
                class="col-12 text-end advanced-search"
            >
                <button
                    type="button"
                    class="btn btn-lg btn-outline-secondary ms-2"
                    @click.stop="reset()"
                    v-if="!refpage"
                >
                    <!-- @keydown.stop.prevent="reset()" -->
                    Reset
                </button>
                <button
                    type="button"
                    class="btn btn-lg btn-outline-secondary ms-2"
                    @click.stop="cancel()"
                    v-if="refpage"
                >
                    Cancel
                </button>

                <button
                    type="button"
                    class="btn btn-lg btn-primary ms-2"
                    @click.stop="search()"
                    :disabled="loading"
                >
                    Search
                    <div class="loader" v-if="loading"></div>
                </button>
                <field-error
                    v-if="serviceError"
                    :message="serviceErrorMessage"
                    :errorCode="serviceError"
                />
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h4>Researcher and Organization</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="FiscalYear"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="PI"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="Organization"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 search-field-container">
                    <field name="City"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 search-field-container">
                    <field name="State"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 search-field-container">
                    <field name="Country"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 search-field-container">
                    <field name="CongressionalDistrict"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="DepartmentTypes"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="OrganizationTypes"/>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h4>Text Search</h4>
                </div>
            </div>

            <div class="row">


                <div class="col-lg-2 col-md-2 col-sm-12">
                    <field name="Operator"/>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                    <field name="SearchText"/>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12">
                    <field name="TextSearchField"/>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h4>Project Details</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="AgencyIC"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="SpendingCategories"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="fundingMechanisms"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="AwardTypes"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 project-number-group">
                    <field name="ProjectNumber" class="project-number-multi" />
                    <div class="row project-number-split">
                        <div class="project-number-or">OR</div>
                        <div class="col-lg-1 col-md-1 col-sm-1 lessPadding char-1">
                            <field name="appl_type_code" />
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 lessPadding char-3">
                            <field name="activity_code" />
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 lessPadding char-2">
                            <field name="ic_code" />
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 lessPadding char-6">
                            <field name="serial_num" />
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 lessPadding char-2">
                          <field name="support_year" />
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 lessPadding char-4">
                            <field name="suffix_code" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="ActivityCodes"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="CooperativeAgreement"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="StudySections"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="PO"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="ProjectStartDate"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="ProjectEndDate"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="AwardNoticeDate"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="FOA"/>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <h4>Additional Filters</h4>
                </div>
            </div>

            <div class="row">

                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="covid19"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="AwardAmountRange"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 stackable-fields">
                    <field name="ExcludeSubprojects"/>
                    <field name="outcomesOnly"/>
                    <field name="multiplePIOnly"/>
                    <field name="newlyAddedProjectsOnly"/>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <field name="ARRASearch"/>
                </div>
            </div>

            <!-- <div class="row justify-content-end"> -->
                <div class="col-12 text-end">
                    <button
                        type="button"
                        class="btn btn-lg btn-outline-secondary ms-2"
                        @click.stop="reset()"
                        v-if="!refpage"
                    >
                        <!-- @keydown.stop.prevent="reset()" -->
                        Reset
                    </button>
                    <button
                        type="button"
                        class="btn btn-lg btn-outline-secondary ms-2"
                        @click.stop="cancel()"
                        v-if="refpage"
                    >
                        Cancel
                    </button>

                    <button
                        type="button"
                        class="btn btn-lg btn-primary ms-2"
                        @click.stop="search()"
                        :disabled="loading"
                    >
                        Search
                        <div class="loader" v-if="loading"></div>
                    </button>
                    <field-error
                        v-if="serviceError"
                        :message="serviceErrorMessage"
                        :errorCode="serviceError"
                    />
                </div>
            <!-- </div> -->
        </div>

        <div class="row" v-if="!showAllFields">
            <div class="col-12 text-center">

                <button class="w-100 alert alert-light show-more-banner" id="btnShowHideForm" role="alert" @click.stop="toggleAllFields">
                    <strong class="text-primary">Looking for additional search fields?</strong>
                    <span class="ms-3">
                        Click here to view All Search Fields
                        <span class="ms-3"><i class="fas fa-expand-alt"></i></span>
                    </span>
                </button>

            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-12 search-utility-buttons">
                <button class="btn btn-sm btn-light btn-show-all-fields" @click.stop="toggleAllFields" id="btnMiniSearch">
                    <span v-show="showAllFields"><i class="fas fa-compress-alt"></i></span> <!-- Workaround for FA, use NPM version of FA instead -->
                    <span v-show="!showAllFields"><i class="fas fa-expand-alt"></i></span>
                    {{ showAllFields ? 'Mini Search' : 'All Search Fields' }}
                </button>
            </div>
            <!-- <div class="col-md-12">
                Data as of {{lastRefreshed}}
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Field from '@/components/searchFields/Field.vue';
import FieldError from '@/components/searchFields/FieldError.vue'
import globalMixins from '@/globalMixins';
// import MultiSelect from '@/components/searchFields/MultiSelect.vue';
// import InputText from '@/components/searchFields/InputText.vue';

// import FiscalYear from '@/components/searchFields/FiscalYear.vue';
// import AgencyIC from '@/components/searchFields/AgencyIC.vue';

import QueryDetailsForm from '@/components/QueryDetailsForm.vue';
import bus from '@/shared/event-bus.js';

export default {
    name: 'ProjectsSearch',
    mixins: [globalMixins],
    components: {
        Field,
        FieldError,
        QueryDetailsForm
    },
    computed: {
        ...mapGetters([
            'searchCriteria',
            'searchFieldErrors',
            'editSearchResults',
            'searchFacetCriteria',
            'editSearchCriteria',
            'searchType',
            'externalURLs'
        ])
    },
    props: {
        refpage: {
            required: false
        }
    },
    data: function () {
        return {
            showAllFields: false,
            parsedCriteria: null,
            loading: false,
            criteria: null,
            sharedSpec: window.searchFieldSpecs,
            serviceErrorMessage: '',
            serviceError: null,
            // lastRefreshed: window.getPreviousMonday(1)
        };
    },
    created () {
        if (this.$route.path === '/advanced-search') {
            this.showAllFields = true;
            this.setSearchMode('advanced-search');
        }

        var criteria = window.parseCriteria(this.$store.state.searchCriteria, {});

        // reset existing search criteria when user is on home page search form
        if (this.$store.state.editSearchResults) {
            this.setEditSearchCriteria(null);
        }

        this.criteria = criteria;
        this.parsedCriteria = window.stringifySearchCriteriaFields(criteria, {isEditCriteria: true});

        bus.$on('load:advance-search', () => {
            if (!this.showAllFields) {
                this.toggleAllFields();
            }
        });
    },
    methods: {
        ...mapActions([
            'setSearchCriteria',
            'setSearchMeta',
            'setSearchResults',
            'setEditSearchResultsFlag',
            'setSearchMode',
            'setSearchType',
            'setSearchFieldErrors',
            'setCurrentQueryDetailsAction',
            'setEditSearchCriteria'
        ]),
        // handleInputEvent (event) {
        //     let SmartSearchText = this.$store.state.searchCriteria.smart_search_text;

        //     if (event.keyCode === 13) {
        //         if (SmartSearchText === '' || SmartSearchText === null || SmartSearchText === undefined) {
        //             if (event.target && event.target.classList && !event.target.classList.contains('btn-clear') && !event.target.classList.contains('multiselect__remove_all') && !event.target.classList.contains('multiselect__tag__remove') && !event.target.classList.contains('btn-show-all-fields')) {
        //                 event.preventDefault();

        //                 //submit search
        //                 this.search();
        //             }
        //         }
        //     }
        // },
        // parseCriteria (criteria) {
        //     // parse search criteria to not include children
        //     // if parent is selected
        //     let newCriteria = {};

        //     for (let field in criteria) {
        //         if (typeof criteria[field] !== 'string') {
        //             // setup each multiselect field
        //             if (criteria[field].length !== 0) {
        //                 newCriteria[field] = [];
        //             }

        //             if (typeof criteria[field][0] === 'string') {
        //                 newCriteria[field] = copyData(criteria[field]);
        //             } else if (typeof criteria[field][0] === 'object') {
        //                 for (let i = 0; i < criteria[field].length; i++) {
        //                     let isParentSelected = false;

        //                     // console.log('value', criteria[field][i].value);
        //                     // console.log('parent', criteria[field][i].parent_value);

        //                     if (criteria[field][i].parent_value) {
        //                         isParentSelected = criteria[field].some((item) => {
        //                             // console.log('item', item.value);
        //                             // console.log(item.value === criteria[field][i].parent_value);
        //                             return item.value === criteria[field][i].parent_value;
        //                         });
        //                     }

        //                     // console.log('isParentSelected', isParentSelected);

        //                     // only add value to parsed criteria if
        //                     // its parent value is not selected
        //                     if (!isParentSelected) {
        //                         newCriteria[field].push(criteria[field][i]);
        //                     }
        //                 }

        //                 // TODO: use field specific delimiter in future
        //                 // newCriteria[field] = newCriteria[field].join(';');
        //             }
        //         } else if (field.toLowerCase().indexOf('__field_text') === -1) {
        //             // if name contains 'field_text' do not pass in search
        //             if (criteria[field]) {
        //                 // only pass field if it has content
        //                 newCriteria[field] = copyData(criteria[field]);
        //             }
        //         }
        //     }

        //     return newCriteria;
        // },

        // stringifySearchCriteriaFields (criteria) {
        //     var stringifiedCriteria = {};

        //     for (let field in criteria) {
        //         if (typeof criteria[field] !== 'string') {
        //             if (criteria[field].length !== 0) {
        //                 stringifiedCriteria[field] = [];
        //             }

        //             if (typeof criteria[field][0] === 'string') {
        //                 stringifiedCriteria[field] = criteria[field].join(';');
        //             } else if (typeof criteria[field][0] === 'object') {

        //                 for (let i = 0; i < criteria[field].length; i++) {
        //                     if (criteria[field][i].value) {
        //                         stringifiedCriteria[field].push(criteria[field][i].value);
        //                     }
        //                 }

        //                 // TODO: use field specific delimiter in future
        //                 stringifiedCriteria[field] = stringifiedCriteria[field].join(';');
        //             }
        //         } else if (field.toLowerCase().indexOf('__field_text') === -1) {
        //             // if name contains 'field_text' do not pass in search
        //             if (criteria[field]) {
        //                 // only pass field if it has content
        //                 stringifiedCriteria[field] = criteria[field];
        //             }
        //         }
        //     }

        //     return stringifiedCriteria;
        // },

        reset () {
            //TODO: use actions/mutators....do not edit state directly from components.
            this.$store.state.searchCriteria = copyData(this.$store.state.searchCriteriaDefault);
            for (let field in this.searchFieldErrors) {
                this.setSearchFieldErrors({
                    field: field
                });
            }

            //TODO: use actions/mutators....do not edit state directly from components.
            if (!(Object.keys(this.$store.state.editSearchCriteria).length === 0 && this.$store.state.editSearchCriteria.constructor === Object)) {
                this.$store.state.editSearchCriteria = copyData(this.$store.state.searchCriteriaDefault);
            }

            bus.$emit('reset');

            this.serviceError = null;
			
			gtag('event','Click', {
				'event_category': this.refpage ? 'Search Results' : 'Home',
				'event_label': 'Projects Search - Reset',
				'value': 'Projects Search - Reset'
			});	
        },

        cancel () {
            this.$parent.showCriteria = false;
			gtag('event','Click', {
				'event_category': 'Search Results',
				'event_label': 'Projects Search Results - Cancel',
				'value': 'Projects Search Results - Cancel'
			});	
        },

        clear () {
            this.setSearchCriteria(null);
            this.serviceError = null;
			
			gtag('event','Click', {
				'event_category': this.refpage ? 'Search Results' : 'Home',
				'event_label': 'Projects Search - Clear',
				'value': 'Projects Search - Clear'
			});	
        },

        toggleAllFields () {
            if (this.$parent.toggleFullForm) {
                this.$parent.toggleFullForm();
            }

            if (this.showAllFields) {
                this.$router.push('/');
                this.setSearchMode('');
                // window.createCookie('searchMode', '', 1);
                window.sessionStorage.setItem('searchMode', '');
				gtag('event','Click', {
					'event_category': this.refpage ? 'Search Results' : 'Home',
					'event_label':'Projects Search - Hide Fields',
					'value': 'Projects Search - Hide Fields'
				});	
            } else {
                this.$router.push('/advanced-search');
                this.setSearchMode('advanced-search');
                // window.createCookie('searchMode', 'advanced-search', 1);
                window.sessionStorage.setItem('advanced-search', '');
				gtag('event','Click', {
					'event_category': this.refpage ? 'Search Results' : 'Home',
					'event_label': 'Projects Search - Show All Fields',
					'value': 'Projects Search - Show All Fields'
				});	
            }

            this.showAllFields = !this.showAllFields;
        },

        showHideFields () {
            var fieldContainers = this.$el.getElementsByClassName('search-field-container');

            if (!this.showAllFields) {
                for (let i = 0; i < fieldContainers.length; i++) {
                    let container = fieldContainers[i],
                        field = container.getElementsByClassName('search-field')[0];

                    setTimeout(() => {
                        let openHeight = field.offsetHeight;

                        // field.style.position = 'absolute';
                        field.classList.add('transition');

                        container.style.height = '0px';
                        container.style.transition = '0.3s';
                        container.style.overflow = 'hidden';

                        setTimeout(() => {
                            container.style.height = openHeight + 'px';

                            setTimeout(() => {
                                container.style.transition = '';
                                container.style.overflow = '';
                                // field.style.position = '';
                                field.classList.remove('transition');
                                // container.style.height = '';
                            }, 3000);
                        }, 1);
                    }, 1);
                    // });
                }
            } else {
                for (let i = 0; i < fieldContainers.length; i++) {
                    let container = fieldContainers[i],
                        field = container.getElementsByClassName('search-field')[0];

                    this.$nextTick(() => {
                        // console.log(field);
                        // console.log('offsetHeight', field.offsetHeight);

                        // let openHeight = field.offsetHeight;
                        // field.style.position = 'absolute';
                        field.classList.add('transition');

                        container.style.transition = '0.3s';
                        container.style.height = '0px';
                        container.style.overflow = 'hidden';
                        // setTimeout(() => {
                        //     container.style.height = openHeight + '0px';
                        setTimeout(() => {
                            // container.style.height = '';
                            container.style.transition = '';
                            container.style.overflow = '';
                            // field.style.position = '';
                            field.classList.remove('transition');
                        }, 3000);
                        // }, 1);
                    });
                }
            }
        },

        formHasErrors () {
            for (let field in this.searchFieldErrors) {
                if (this.searchFieldErrors[field].preventSearch) {
                    return true;
                }
            }

            return false;
        },

        search () {
            if (this.searchCriteria['smart_search_text']) {
                this.searchCriteria['smart_search_text'] = '';
            }
            if (this.searchCriteria['matchmaker_text']) {
                this.searchCriteria['matchmaker_text'] = '';
            }
            if (this.searchCriteria['publications_text']) {
                this.searchCriteria['publications_text'] = '';
            }

            var parsedCriteria = window.parseCriteria(this.searchCriteria, {isEditCriteria: false, isSubmitForm: true}),
                criteria = window.stringifySearchCriteriaFields(parsedCriteria, {});

            this.serviceError = null;

            if (!this.formHasErrors()) {
				gtag('event','Click', {
					'event_category': this.refpage ? 'Search Results' : 'Home',
					'event_label': 'Projects Search - Submit',
					'value': 'Projects Search - Submit'
				});	

                //remove the currentQueryDetails when doing a new search...
                this.setCurrentQueryDetailsAction(null);

                this.loading = true;

                this.setEditSearchResultsFlag(false);
                this.setEditSearchCriteria(null);
                
                this.setSearchType('Advanced');
                this.clearSearchResults({resetFacets: true});
                this.getProjectSearchResults(false, this.searchResults);
            } else {
                console.error('form validation error');
            }
			
			gtag('event','Click', {
				'event_category': this.refpage ? 'Search Results' : 'Home',
				'event_label': 'Projects Search - Submit',
				'value': 'Projects Search - Submit'
			});					
        },

        searchResults (data) {
            this.loading = false;

            if (data && data.status && data.status !== 200) {
                if (!data.results) {
                    this.serviceError = this.externalURLs.reporter_project_search_error_message;
                    return false;
                }
            } else if (!data) {
                this.serviceError = this.externalURLs.reporter_project_search_error_message;
            } else {
                if (data.meta && data.results) {
                    var url = '/search/';
                    this.setSearchMeta(data.meta);
                    this.setSearchResults(data.results);

                    // default to Project results
                    url += data.meta.search_id + '/projects';//?searchMode=' + this.searchType;

                    if (this.$parent.sortField) {
                        url += '&sort_field=' + this.$parent.sortField + '&sort_order=' + this.$parent.sortOrder;
                    }
                    this.$router.push(url);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import '@/scss/variables.scss';
@import 'node_modules/bootstrap/scss/_mixins';

.loader {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 4em;
    height: 4em;
    top: -2px;
    left: 0;
    margin-left: 0.5em;
    border-width: 2px;
    border-top-color: rgba(255, 255, 255, 0.25);
    border-right-color: rgba(255, 255, 255, 0.25);
    border-bottom-color: rgba(255, 255, 255, 0.25);
    border-left-color: rgba(255, 255, 255, 1);
}

.search-field {
    transition: 0.3s;
}

.loading {
    .search-field {
        opacity: 0.5;
    }
}

.lessPadding {
    padding: 0 2px;
}

.hidden {
    opacity: 0;
    // visibility: hidden;
}

.hidden-absolute {
    position: absolute;
    opacity: 0;
    // visibility: hidden;
}

.animate {
    transition: 0.3s;
}

// .search-form {
// }

.search-field-container {
    position: relative;
}

.alert-light {
    span {
        color: $grey-med;
    }
}
// .search-buttons {
//     font-size: 18px;
//     margin-top: 0;
//     margin-top: 1.5em;
//     margin-bottom: 1.5em;
//     &.advanced-search {
//         margin-top: 0;
//         margin-bottom: 0;
//         &.search-buttons-top {
//             @media screen and (min-width: $screen-sm) {
//                 margin-top: -42px;
//             }
//         }
//     }
//     .btn {
//         padding-left: 0;
//         padding-right: 0;
//         + .btn {
//             margin-left: 0.75em;
//         }
//     }
// }

.search-utility-buttons {
    text-align: center;
    margin-top: 10px;
}

// .btn-clear {
//     width: calc(45% - 0.75em);
// }

// .btn-search {
//     width: 55%;

//     &:disabled {
//         opacity: 1;
//     }
// }

.btn-primary {
    // background-color: $green-dark;

    @include button-variant($green-dark, $green-dark);
}

.btn-round {
    @include button-variant($green-light, $green-light);
    color: $green-dark;

    min-width: calc(33% - #{$grid-gutter-width * 0.5});
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.absolute-fade-fade-enter-active {
  transition: all .3s ease;
  position: absolute;
}
.absolute-fade-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.absolute-fade-fade-enter, .absolute-fade-fade-leave-to
/* .absolute-fade-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.show-more-banner:hover {
    border: 1px solid $primary;
    cursor: pointer;
    // color: green;
    background-color: #F0F0F0;
}

//https://css-tricks.com/snippets/css/shake-css-keyframe-animation/
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.show-more-banner:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
//   backface-visibility: hidden;
//   perspective: 1000px;
}

</style>
