import { mapActions, mapGetters } from 'vuex';
import bus from '@/shared/event-bus.js';

export default {
  computed: {
    ...mapGetters([
      'searchFacetCriteria',
      'matchmakerFacetCriteria',
      'publicationsFacetCriteria',
      'graphSettings',
      'searchType',
      'currentExportTab',
      'selectedPerson',
      'editSearchCriteria',
      'externalURLs'
    ]),
  },
  data () {
    return {
      notificationSystem: {
        options: {
          show: {
            theme: 'dark',
            icon: 'icon-person',
            position: 'topCenter',
            progressBarColor: 'rgb(0, 255, 184)',
            buttons: [
              ['<button>Ok</button>', function (instance, toast) {
                alert("Hello world!");
              }, true],
              ['<button>Close</button>', function (instance, toast) {
                instance.hide({
                  transitionOut: 'fadeOutUp',
                  onClosing: function (instance, toast, closedBy) {
                    console.info('closedBy: ' + closedBy);
                  }
                }, toast, 'buttonName');
              }]
            ],
            onOpening: function (instance, toast) {
              console.info('callback abriu!');
            },
            onClosing: function (instance, toast, closedBy) {
              console.info('closedBy: ' + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: 'bottomCenter'
          },
          info: {
            position: 'bottomLeft'
          },
          success: {
            position: 'bottomRight'
          },
          warning: {
            position: 'topLeft'
          },
          error: {
            close: true,
            position: 'topRight',
            timeout: 5000,
            overlay: false,
          },
          question: {
            timeout: 20000,
            close: false,
            overlay: true,
            toastOnce: true,
            id: 'question',
            zindex: 999,
            position: 'center',
            buttons: [
              ['<button><b>YES</b></button>', function (instance, toast) {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
              }, true],
              ['<button>NO</button>', function (instance, toast) {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
              }]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info('Closing | closedBy: ' + closedBy);
            },
            onClosed: function (instance, toast, closedBy) {
              console.info('Closed | closedBy: ' + closedBy);
            }
          }
        }
      }
    };
  },
  mounted () {
    setTimeout(() => {
      this.initWindowEvents();
    }, 1);

    bus.$on('get:searchCriteria', () => {
      if (!this.isLoadingSearchCriteria) {
        this.isLoadingSearchCriteria = true;
        this.getSearchCriteria();
      }
    });

    bus.$off('update:searchResults');
    bus.$on('update:searchResults', (flag, callback) => {
      switch (this.$route.name) {
        case 'list':
        case 'home':
        case 'advanced-search':
        case 'matchmaker':
        case 'publications-search':
          this.getProjectSearchResults(flag, callback);
          break;
        case 'charts':
          this.getProjectSearchGraphData(flag, callback);
          break;
        case 'map':
          this.$refs.googleMap.getProjectSearchMapData(flag, (data) => {
            callback(data);
          });
      }
    });

    bus.$off('update:searchResultsData');
    bus.$on('update:searchResultsData', (data) => {
      switch (this.$route.name) {
        case 'list':
          this.updateProjectSearchResults(data);
          break;
        case 'charts':
          this.handleGraphData(data);
          break;
        case 'map':
          this.$refs.googleMap.resetMapFilters();
          this.updateURLSearchId(data.meta.search_id);
          this.$refs.googleMap.updateMap();
          this.$refs.googleMap.getTableData(true);
          this.$refs.facets.getFacets(false);
          break;
        default:
      }
      this.$refs.facets.getFacets(true);
    });

    bus.$off('clear:searchResults');
    bus.$on('clear:searchResults', (params) => {
      this.clearSearchResults(params);
    });

    bus.$off('update:facets');
    bus.$on('update:facets', () => {
      this.$refs.facets.getFacets(true);
    });
  },
  beforeDestroy () {
    bus.$off('update:searchResults');
    bus.$off('clear:searchResults');
    bus.$off('update:searchResultsData');
    bus.$off('update:facets');
  },
  methods: {
    ...mapActions([
      'setSearchCriteria',
      'setEditSearchCriteria',
      'setSearchMeta',
      'setSearchResults',
      'updateSearchResults',
      'setEditSearchResultsFlag',
      'setGraphData',
      'setCirclesData',
      'setPublicationResults',
      'setCoreProjCountPub',
      'setProjectDetails',
      'setExportFields',
      'setPatentResults',
      'setClinicalStudyResults',
      'setMatchmakerResults',
      'setMatchmakerPOResults',
      'updatePublicationResults',
      'updatePatentResults',
      'updateClinicalStudyResults',
      'updateMatchmakerResults',
      'setSearchFacetCriteria',
      'setSearchFacetData',
      'updateSearchResultsFacets',
      'setPublicationsFacetData',
      'setPublicationsFacetCriteria',
      'setMatchmakerFacetData',
      'setMatchmakerFacetCriteria',
      'setSearchType',
      'setPersonSummaryProjects',
      'setSelectedPerson',
      'resetNewsResults'
    ]),
    initWindowEvents () {
      var self = this,
        sections = document.getElementsByClassName('sidebar-sections');

      window.onscroll = function ($event) {
        if (self.$route && self.$route.name && (self.$route.name === 'project-details' || self.$route.name === 'publications-project-details' || self.$route.name.indexOf('search-project-details') === 0)) {
          window.fixHeaders('project-basics', true);

          let activeTab = document.querySelector('.sidebar a.active');
          for (let i = 0; i < sections.length; i++) {
            let currentSection = sections[i].getAttribute('id');
            let currentSectionHeader = sections[i].querySelector('.section-header');
            let bounding = currentSectionHeader.getBoundingClientRect();

            if (
              bounding.top >= 0 &&
              bounding.left >= 0 &&
              bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            ) {
              if (activeTab && !self.scrollLock) {
                activeTab.classList.remove('active');
              }
              let navBarTab = document.querySelector('a[href*="' + currentSection + '"]');
              if (navBarTab && self.loadSectionData) {
                self.loadSectionData(currentSection);
                // break;
              }
            }
          }
          if (!self.scrollLock) {
            for (let i = 0; i < sections.length; i++) {
              let currentSection = sections[i].getAttribute('id');
              if (window.pageYOffset < (sections[i].offsetTop + sections[i].offsetHeight - 150)) {
                if (currentSection) {
                  if (activeTab) {
                    activeTab.classList.remove('active');
                  }
                  let navBarTab = document.querySelector('a[href*="' + currentSection + '"]');
                  if (navBarTab) {
                    navBarTab.classList.add('active');
                    break;
                  }
                }
              }
            }
          }
        } else {
          bus.$emit('update:scroll');
        }
      }

      window.addEventListener('resize', () => {
        window.calculateViewPortSize ? window.calculateViewPortSize() : null;

        document.querySelector("#scrollTable") ? document.querySelector("#scrollTable").removeAttribute("style") : null;
        bus.$emit('update:resize');
        if (self.$route.name === 'project-details' || self.$route.name === 'search-project-details' || self.$route.name === 'news') {
          var tables = document.getElementsByClassName('fixed-within'),
            containerParent,
            curTableObj;

          for (let i = 0; i < tables.length; i++) {
            curTableObj = tables[i];
            containerParent = window.closest(curTableObj, '.project-description');

            window.sizeTableHeader(containerParent.getAttribute('id'));
          }
        }
      });
    },
    getPublicationSearchResults (changedCriteria, callback) {
      if (changedCriteria && this.publicationResultsTable) {
        this.publicationResultsTable.offset = 0;
        this.countLoader = true;
      }

      var searchCriteriaTemp = {
        criteria: {},
        facet_filters: this.publicationsFacetCriteria,
        limit: this.publicationResultsTable ? this.publicationResultsTable.limit : 100,
        offset: this.publicationResultsTable ? this.publicationResultsTable.offset : 0,
        sort_field: this.publicationResultsTable ? this.publicationResultsTable.sortField : null,
        sort_order: this.publicationResultsTable ? this.publicationResultsTable.sort_order : 'desc'
      },
        searchParam,
        key,
        value;


      if (!changedCriteria && this.$route.params.search_id) {
        searchCriteriaTemp['search_id'] = this.$route.params.search_id;
      } else {
        key = this.searchCriteria['publications_field'];
        value = _.filter(this.searchCriteria['publications_text'].split(/[;,\s*]+/gi), id => !!id);

        searchParam = {
          publications_search: {
            [key]: value
          }
        };
        searchCriteriaTemp.criteria = searchParam;
      }

      if (!!callback) {
        window.fetchPublicationSearchResults(searchCriteriaTemp, callback);
      } else {
        window.fetchPublicationSearchResults(searchCriteriaTemp, this.updatePublicationsSearchResults);
      }
    },
    updatePublicationsSearchResults (data) {
      this.publicationResultsTable.isLoading = false;
      this.publicationResultsTable.loadingMoreData = false;
      this.publicationResultsTable.loadingMoreData = false;

      if (this.$refs && this.$refs.dataTable) {
        this.$refs.dataTable.loadingMoreData = false;
      }

      if (!data || (data && data.status && data.status !== 200)) {
        this.setPublicationResults(null);
        this.serviceError = this.externalURLs.reporter_project_search_error_message;
        return false;
      } else {
        if (data) {
          data.meta.sort_field = this.publicationResultsTable.sortField;
          data.meta.sort_order = this.publicationResultsTable.sortOrder;
          if (this.publicationResultsTable.offset === 0) {
            this.setPublicationResults(data);
          } else {
            this.updatePublicationResults(data);
          }
          if (this.$refs && this.$refs.dataTable) {
            this.$refs.dataTable.initTableData();
          }
          setTimeout(() => {
            if (data.results.length > 0) {
              window.fixHeaders('scrollTable');
            }
          }, 10);
        }
      }
    },
    getMatchmakerSearchResults (changedCriteria, newSearch, callback) {
      if (changedCriteria && this.matchMakerListTable) {
        this.matchMakerListTable.offset = 0;
      }

      var searchCriteriaTemp = {
        criteria: {},
        facet_filters: this.matchmakerFacetCriteria,
        limit: this.matchMakerListTable ? this.matchMakerListTable.limit : 100,
        offset: this.matchMakerListTable ? this.matchMakerListTable.offset : 0,
        sort_field: this.matchMakerListTable ? this.matchMakerListTable.sortField : '',
        sort_order: this.matchMakerListTable ? this.matchMakerListTable.sortOrder : ''
      },
        parsedCriteria,
        criteria,
        searchParam;

      searchParam = {
        title: '',
        text: this.searchCriteria['matchmaker_text'],
        include_active_projects: true
        // text: 'this is a test Warburg Effect',
        // fiscal_years: '',
        // agencies: ''
      };

      if (!changedCriteria && this.$route.params.search_id) {
        searchCriteriaTemp['search_id'] = this.$route.params.search_id;
        searchCriteriaTemp.criteria = {
          include_active_projects: this.$refs.facets && this.$refs.facets.$refs.facets ? this.$refs.facets.$refs.facets.facetCriteria.is_active_projects : true
        }
      } else {
        if (this.$refs.facets && this.$refs.facets.$refs.facets) {
          if (!newSearch) {
            searchParam.include_active_projects = this.$refs.facets.$refs.facets.facetCriteria.is_active_projects;
          } else {
            this.$refs.facets.$refs.facets.facetCriteria.is_active_projects = true;
          }
        }
        searchCriteriaTemp.criteria = searchParam;
      }
      // if (this.$refs.Charts && (this.$refs.Charts.loadingDrillDownData || this.$refs.Charts.loadSlide)) {
      //     searchParam.criteria = this.chartProjectsSearchCriteria;
      //     searchParam.facet_filters = this.chartProjectsFilterBy;
      // }

      // if (this.projectListTable && this.projectListTable.sortField) {
      //     searchParam.sort_field = this.projectListTable.sortField;
      //     searchParam.sort_order = this.projectListTable.sortOrder
      // }

      // if (this.projectListTable) {
      //     this.projectListTable.isLoading = true;
      // }
      // if (this.$refs && this.$refs.searchCriteria) {
      //     this.$refs.searchCriteria.showCriteria = false;
      // }

      if (!!callback) {
        window.getMatchmakerResults(searchCriteriaTemp, callback);
      } else {
        window.getMatchmakerResults(searchCriteriaTemp, this.updateMatchmakerSearchResults);
      }
    },
    getMatchmakerPOResults (changedCriteria, newSearch, callback) {
      if (changedCriteria && this.POListTable) {
        this.POListTable.offset = 0;
        this.POListTable.limit = 100;
      }

      var searchCriteriaTemp = {
        criteria: {},
        facet_filters: this.matchmakerFacetCriteria,
        limit: this.POListTable ? this.POListTable.limit : 100,
        offset: this.POListTable ? this.POListTable.offset : 0,
        sort_order: this.POListTable ? this.POListTable.sortOrder : '',
        sort_field: this.POListTable ? this.POListTable.sortField : ''
      },
        parsedCriteria,
        criteria,
        searchParam;

      searchParam = {
        title: '',
        text: this.searchCriteria['matchmaker_text'],
        include_active_projects: true
        // text: 'this is a test Warburg Effect',
        // fiscal_years: '',
        // agencies: ''
      };

      if (!changedCriteria && this.$route.params.search_id) {
        searchCriteriaTemp['search_id'] = this.$route.params.search_id;
        searchCriteriaTemp['cache_id'] = this.$store.state.matchMakerResults ? this.$store.state.matchMakerResults.meta.properties.CacheId : null
        searchCriteriaTemp.criteria = {
          include_active_projects: this.$refs.facets && this.$refs.facets.$refs.facets ? this.$refs.facets.$refs.facets.facetCriteria.is_active_projects : true
        }
      } else {
        if (this.$refs.facets && this.$refs.facets.$refs.facets) {
          if (!newSearch) {
            searchParam.include_active_projects = this.$refs.facets.$refs.facets.facetCriteria.is_active_projects;
          } else {
            this.$refs.facets.$refs.facets.facetCriteria.is_active_projects = true;
          }
        }
        searchCriteriaTemp.criteria = searchParam;
      }

      if (!!callback) {
        window.getMatchmakerPOResults(searchCriteriaTemp, callback);
      } else {
        window.getMatchmakerPOResults(searchCriteriaTemp, this.updateMatchmakerPOResults);
      }
    },
    updateMatchmakerSearchResults (data) {
      this.matchMakerListTable.loadingMoreData = false;

      // if (data.meta && data.results) {
      if (!data || (data && data.status && data.status !== 200)) {
        this.setMatchmakerResults(null);
        this.serviceError = this.externalURLs.reporter_project_search_error_message;
        return false;
      } else {
        if (data) {
          this.setSearchMeta(data.meta);
          if (this.matchMakerListTable.offset === 0) {
            this.setMatchmakerResults(data);
          } else {
            this.updateMatchmakerResults(data);
          }
          if (this.matchMakerListTable.selectedSortColumn) {
            this.matchMakerListTable.isLoading = true;
            this.setSortDetails(null, this.matchMakerListTable.selectedSortColumn);
          } else if (this.$refs && this.$refs.dataTable) {
            this.matchMakerListTable.isLoading = false;
            this.$refs.dataTable.initTableData();
          }
          bus.$emit('matchmaker:updateChartFacet');
          setTimeout(() => {
            this.matchMakerListTable.selectedSortColumn = null;
            if (data.results.length > 0) {
              window.fixHeaders('scrollTable');
            }
          }, 10);
        }
      }
    },
    updateMatchmakerPOResults (data) {
      this.POListTable.isLoading = false;
      this.POListTable.loadingMoreData = false;
      this.captchaValidated = false;

      // if (data.meta && data.results) {
      if (!data || (data && data.status && data.status !== 200)) {
        this.setMatchmakerPOResults(null);
        this.serviceError = this.externalURLs.reporter_project_search_error_message;
        return false;
      } else {
        if (data) {
          if (this.isCaptchaRequested) {
            this.captchaValidated = true;
          }
          this.setSearchMeta(data.meta);
          if (this.POListTable.offset === 0) {
            this.setMatchmakerPOResults(data);
          } else {
            this.updateMatchmakerPOResults(data);
          }
          if (this.$refs && this.$refs.dataTable) {
            this.$refs.dataTable.initTableData();
          }
          setTimeout(() => {
            if (data.results.length > 0) {
              window.fixHeaders('scrollTable');
            }
          }, 10);
        }
      }
    },
    getNewSearchCriteria (changedCriteria) {
      var searchCriteriaTemp = copyData(this.searchCriteria),
        parsedCriteria,
        criteria,
        searchParam;

      if (this.searchType === 'Smart') {
        searchCriteriaTemp = copyData(this.$store.state.searchCriteriaDefault);
        searchCriteriaTemp['smart_search_text'] = this.searchCriteria['smart_search_text'];
        searchCriteriaTemp['fiscal_years'] = '';
      } else {
        if ((changedCriteria || !this.$route.params.search_id) && (searchCriteriaTemp['fiscal_years'] === null || searchCriteriaTemp['fiscal_years'].length === 0 || searchCriteriaTemp['fiscal_years'] === '')) {
          searchCriteriaTemp['fiscal_years'] = ['ap'];
        }
      }

      parsedCriteria = window.parseCriteria(searchCriteriaTemp, { isSubmitForm: false });
      criteria = window.stringifySearchCriteriaFields(parsedCriteria);

      return criteria;
    },
    getProjectSearchResults (changedCriteria, callback) {
      if (changedCriteria && this.projectListTable) {
        this.projectListTable.offset = 0;
      }

      // var searchCriteriaTemp = copyData(this.searchCriteria),
      //     parsedCriteria,
      //     criteria,
      //     searchParam;

      // if (this.searchType === 'Smart') {
      //     searchCriteriaTemp = copyData(this.$store.state.searchCriteriaDefault);
      //     searchCriteriaTemp['smart_search_text'] = this.searchCriteria['smart_search_text'];
      //     searchCriteriaTemp['fiscal_years'] = '';
      // } else {
      //     if ((changedCriteria || !this.$route.params.search_id) && (searchCriteriaTemp['fiscal_years'] === null || searchCriteriaTemp['fiscal_years'].length === 0 || searchCriteriaTemp['fiscal_years'] === '')) {
      //         searchCriteriaTemp['fiscal_years'] = ['ap'];
      //     }
      // }

      // parsedCriteria = window.parseCriteria(searchCriteriaTemp, {isSubmitForm: false});
      // criteria = window.stringifySearchCriteriaFields(parsedCriteria);

      let criteria = this.getNewSearchCriteria(changedCriteria),
        searchParam;

      searchParam = {
        offset: this.projectListTable ? this.projectListTable.offset : 0,
        limit: this.projectListTable ? this.projectListTable.limit : 100,
        facet_filters: this.searchFacetCriteria,
        criteria: criteria,
        is_shared: this.$route.query['shared'] ? true : false
      };

      if (!changedCriteria && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }

      if (this.$refs.Charts && (this.$refs.Charts.loadingDrillDownData || this.$refs.Charts.loadSlide)) {
        searchParam.criteria = this.chartProjectsSearchCriteria;
        searchParam.facet_filters = this.chartProjectsFilterBy;
      }

      if (this.projectListTable && this.projectListTable.sortField) {
        searchParam.sort_field = this.projectListTable.sortField;
        searchParam.sort_order = this.projectListTable.sortOrder;
      }

      if (this.projectListTable) {
        this.projectListTable.isLoading = true;
      }

      if (callback) {
        window.getSearchResults(searchParam, callback);
      } else {
        window.getSearchResults(searchParam, this.updateProjectSearchResults);
      }

    },
    updateURLSearchId (searchId, page) {

      if (!page) {
        page = this.$route.path.split(this.$route.params.search_id)[1];
      }

      if (searchId !== this.$route.params.search_id) {
        let url = '/search/' + searchId + page;

        if (this.$parent.sortField) {
          url += '?sort_field=' + this.projectListTable.sortField + '&sort_order=' + this.projectListTable.sortOrder;
        }

        this.$router.push(url);
      }
    },
    updateProjectSearchResults (data) {
      this.loading = false;
      if (this.projectListTable) {
        this.projectListTable.isLoading = false;
      }
      if (data.meta && data.results) {
        this.updateURLSearchId(data.meta.search_id);


        this.setSearchMeta(data.meta);
        if (!data.meta.sorted_by_relevance && this.$refs.dataTable && this.$refs.dataTable.table && this.$refs.dataTable.table.sortField === '') {
          this.$refs.dataTable.table.sortField = 'name';
          this.$refs.dataTable.table.sortOrder = 'asc';
        }
        if (data.meta.offset === 0) {
          this.setSearchResults(data.results);
        } else {
          this.updateSearchResults(data.results);
        }
        if (this.$refs && this.$refs.dataTable) {
          this.$refs.dataTable.table.loadingMoreData = false;
          this.$refs.dataTable.initTableData();
        } else if (this.$parent.$parent.$refs && this.$parent.$parent.$refs.dataTable) {
          this.$parent.$parent.$refs.dataTable.table.isLoading = false;
          this.$parent.$parent.$refs.dataTable.table.loadingMoreData = false;
          this.$parent.$parent.$refs.dataTable.initTableData();
        }
        if (this.$refs && this.$refs.Charts) {
          if (this.$refs.Charts.chartType === 'circle') {
            this.$refs.Charts.circlesTable.loadingMoreData = false;
            this.$refs.Charts.$refs.circlesTable.initTableData();
          } else {
            this.$refs.Charts.loadingDrillDownData = false;
            this.$refs.Charts.loadSlide = true;
            this.$refs.Charts.$refs.dataTable.initTableData();
          }
        } else if (this.$parent.$parent.$refs && this.$parent.$parent.$refs.Charts) {
          this.$parent.$parent.$refs.Charts.loadingDrillDownData = false;
          this.$parent.$parent.$refs.Charts.loadSlide = true;
          this.$parent.$parent.$refs.Charts.$refs.dataTable.initTableData();
        }
        this.$nextTick(() => {
          if (data.results.length > 0) {
            window.fixHeaders('scrollTable');
          }
        });
      }
    },
    setProjectSortDetails (selectedSortColumn) {
      var selectedSortField = this.getFieldNameFromSettings(selectedSortColumn),
        facetToQueryStr;

      if (this.projectListTable.sortField === selectedSortField) {
        this.projectListTable.sortOrder = (this.projectListTable.sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        this.projectListTable.sortOrder = selectedSortColumn.sortOrder;
      }
      this.projectListTable.sortField = selectedSortField;
      this.projectListTable.offset = 0;

      facetToQueryStr = this.$refs.facets.generateQueryString();
      facetToQueryStr['sort_field'] = this.projectListTable.sortField;
      facetToQueryStr['sort_order'] = this.projectListTable.sortOrder;

      this.$router.push({ query: facetToQueryStr });
      gtag('event','Click', {
		'event_category': 'Search Results',
		'event_label': 'Search Results - sorted on ' + this.projectListTable.sortField + ' (' + this.projectListTable.sortOrder + ')',
		'value': this.projectListTable.sortField + ' (' + this.projectListTable.sortOrder + ')'
	  });	
	  
      this.getProjectSearchResults();

      //future function
    },
    setMatchmakerSortDetails (selectedSortColumn) {
      var selectedSortField = this.getFieldNameFromSettings(selectedSortColumn),
        facetToQueryStr;

      if (this.projectListTable.sortField === selectedSortField) {
        this.projectListTable.sortOrder = (this.projectListTable.sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        this.projectListTable.sortOrder = selectedSortColumn.sortOrder;
      }
      this.projectListTable.sortField = selectedSortField;
      this.projectListTable.offset = 0;

      facetToQueryStr = this.$refs.facets.generateQueryString();
      facetToQueryStr['sort_field'] = this.projectListTable.sortField;
      facetToQueryStr['sort_order'] = this.projectListTable.sortOrder;

      this.$router.push({ query: facetToQueryStr });
	  gtag('event','Click', {
		'event_category': 'Search Results',
		'event_label': 'Search Results - sorted on ' + this.projectListTable.sortField + ' (' + this.projectListTable.sortOrder + ')',
		'value': this.projectListTable.sortField + ' (' + this.projectListTable.sortOrder + ')'
	  });	
      this.getMatchmakerSearchResults();

      //future function
    },
    setProjectPageNumber (requestedPage) {
      if (requestedPage === this.pageName) {
        this.projectListTable.loadingMoreData = true;
        this.projectListTable.offset += this.projectListTable.limit;
		gtag('event','Scroll', {
			'event_category': 'Search Results',
			'event_label': 'Search Results - page no ' + this.projectListTable.offset / this.projectListTable.limit,
			'value': this.projectListTable.offset / this.projectListTable.limit
		});	

        this.getProjectSearchResults();
      }
      //future function
    },
    getSearchCriteria (callback) {
      var search_id = this.$route.params.search_id;

      if (search_id && search_id.indexOf('CID-') !== 0) {
        window.getProjectSearchCriteria(search_id, this.updateProjectSearchCriteria, callback);
      }
    },
    updateProjectSearchCriteria (data, callback) {
      var projNumSplit = {},
        projNumApplIds = '', projNumFields = '',
        multiSelectData,
        fieldObj,
        searchCriteriaMissing = !this.searchCriteria || !this.searchCriteria.project_start_date;

      this.setEditSearchResultsFlag(true);
      this.isLoadingSearchCriteria = false;
      if (!data || (data && data.status && data.status !== 200)) {
        this.setEditSearchCriteria(null);
        this.serviceError = this.externalURLs.reporter_project_search_error_message;
        return false;
      } else {
        for (let field in data) {
          if (data[field]) {
            switch (field) {
              case 'project_num_split':
              case 'advanced_text_search':
                for (let subField in data[field]) {
                  if (data[field][subField]) {
                    if (subField === 'search_field') {
                      this.setEditSearchCriteria({
                        field: subField,
                        data: copyData(data[field][subField]).split(',')
                      });
                      if (searchCriteriaMissing) {
                        this.setSearchCriteria({
                          field: subField,
                          data: copyData(data[field][subField]).split(',')
                        });
                      }
                    } else {
                      this.setEditSearchCriteria({
                        field: subField,
                        data: copyData(data[field][subField])
                      });
                      if (searchCriteriaMissing) {
                        this.setSearchCriteria({
                          field: subField,
                          data: copyData(data[field][subField])
                        });
                      }
                    }
                  }
                }
                if (!data[field]['operator']) {
                  this.setEditSearchCriteria({
                    field: 'operator',
                    data: 'advanced'
                  });
                  if (searchCriteriaMissing) {
                    this.setSearchCriteria({
                      field: 'operator',
                      data: 'advanced'
                    });
                  }
                }
                if (!data[field]['search_field']) {
                  this.setEditSearchCriteria({
                    field: 'search_field',
                    data: ['All']
                  });
                  if (searchCriteriaMissing) {
                    this.setSearchCriteria({
                      field: 'search_field',
                      data: ['All']
                    });
                  }
                }
                break;
              case 'org_cities':
              case 'foa':
                this.setEditSearchCriteria({
                  field: field,
                  data: data[field]
                });
                if (searchCriteriaMissing) {
                  this.setSearchCriteria({
                    field: field,
                    data: data[field]
                  });
                }
                break;
              case 'pi_names':
              case 'pi_profile_ids':
                this.setEditSearchCriteria({
                  field: 'pi__field_text',
                  data: data[field]
                });
                this.setEditSearchCriteria({
                  field: field,
                  data: data[field]
                });
                if (searchCriteriaMissing) {
                  this.setSearchCriteria({
                    field: 'pi__field_text',
                    data: data[field]
                  });
                  this.setSearchCriteria({
                    field: field,
                    data: data[field]
                  });
                }
                break;
              case 'po_names':
              case 'po_profile_ids':
                this.setEditSearchCriteria({
                  field: 'po__field_text',
                  data: data[field]
                });
                this.setEditSearchCriteria({
                  field: field,
                  data: data[field]
                });
                if (searchCriteriaMissing) {
                  this.setSearchCriteria({
                    field: 'po__field_text',
                    data: data[field]
                  });
                  this.setSearchCriteria({
                    field: field,
                    data: data[field]
                  });
                }
                break;
              case 'project_nums':
              case 'appl_ids':
                projNumFields = field;
                projNumApplIds += (projNumApplIds === '' ? '' : ';') +  data[field];
                break;
              case 'fiscal_years':
                multiSelectData = data[field].split(';')
                this.setEditSearchCriteria({
                  field: 'fiscal_years',
                  data: multiSelectData
                });
                this.setSearchCriteria({
                  field: 'fiscal_years',
                  data: multiSelectData
                });
                break; 
              case 'smart_search_text':
                if (data[field] && data[field] !== '') {
                  this.setSearchType('Smart');
                  this.$store.state.searchCriteria['smart_search_text'] = data[field];
                }
                this.setEditSearchCriteria({
                  field: field,
                  data: data[field]
                });
                break;
              default:
                if (field === 'spending_categories') {
                  for (let key in window.searchFieldSpecs) {
                    if (window.searchFieldSpecs[key] && window.searchFieldSpecs[key].id === field) {
                      fieldObj = window.searchFieldSpecs[key];
                      break;
                    }
                  }
                  if (!data[fieldObj.additionalControl.options[0].id]) {
                    this.setEditSearchCriteria({
                      field: fieldObj.additionalControl.options[0].id,
                      data: false
                    });
                  }
                }
                
                multiSelectData = data[field];
                if (typeof data[field] === 'string') {
                  multiSelectData = data[field].split(';')
                }
                if (field === 'funding_mechanism') {
                  multiSelectData.push(data[field]);
                }
                this.setEditSearchCriteria({
                  field: field,
                  data: multiSelectData
                });
                this.setSearchCriteria({
                  field: field,
                  data: ''
                });
            }
          }
        }
        if (projNumApplIds !== '') {
          this.setEditSearchCriteria({
            field: 'project_nums__field_text',
            data: projNumApplIds
          });
          this.setEditSearchCriteria({
            field: projNumFields,
            data: projNumApplIds.split(';')
          });
          if (searchCriteriaMissing) {
            this.setSearchCriteria({
              field: 'project_nums__field_text',
              data: projNumApplIds
            });
            this.setSearchCriteria({
              field: projNumFields,
              data: projNumApplIds.split(';')
            });
          }
        }
        if (!data.smart_search_text || data.smart_search_text === '') {
          this.setSearchType('Advanced');
        }
      }

      if (callback) {
        callback();
      }
      bus.$emit('criteria:received');
    },
    getFieldNameFromSettings (sortCol) {
      var fieldName = sortCol.fieldName;

      if (sortCol.subField) {
        fieldName = sortCol.subField;
      }
      else if (sortCol.sortFieldName) {
        return fieldName = sortCol.sortFieldName;
      }
      return fieldName;
    },
    getSearchResultsFacets (changedCriteria, callback) {
      var searchCriteriaTemp = this.searchCriteria ? copyData(this.searchCriteria) : {},
        parsedCriteria,
        criteria,
        searchParam;

      if (this.searchType === 'Smart') {
        searchCriteriaTemp = copyData(this.$store.state.searchCriteriaDefault);
        searchCriteriaTemp['smart_search_text'] = this.searchCriteria['smart_search_text'];
        searchCriteriaTemp['fiscal_years'] = '';
      } else {
        if ((changedCriteria || !this.$route.params.search_id) && (!searchCriteriaTemp['fiscal_years'] || searchCriteriaTemp['fiscal_years'] === null || searchCriteriaTemp['fiscal_years'].length === 0 || searchCriteriaTemp['fiscal_years'] === '')) {
          searchCriteriaTemp['fiscal_years'] = ['ap'];
        }
        if (this.$route.name === 'pi-summary' || this.selectedPerson) {
          if (this.selectedPerson) {
            if (this.selectedPerson.role === 'pi') {
              searchCriteriaTemp['pi_profile_ids'] = this.selectedPerson.profile_id;
            } else if (this.selectedPerson.role === 'po') {
              searchCriteriaTemp['po_names'] = this.selectedPerson.name;
            }
          } else {
            searchCriteriaTemp['pi_profile_ids'] = this.$route.params.profile_id;
          }
        }
      }

      // console.log(this.searchCriteria);

      // this.getSearchCriteria();

      parsedCriteria = window.parseCriteria(searchCriteriaTemp, { isSubmitForm: false });
      criteria = window.stringifySearchCriteriaFields(parsedCriteria);

      searchParam = {
        facet_filters: this.searchFacetCriteria,
        criteria: criteria,
        is_shared: this.$route.query['shared'] ? true : false
      };

      if (!changedCriteria && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }

      window.getSearchFacets('Projects', searchParam, callback, this.$route.name);
    },
    getMatchmakerSearchFacets (changedCriteria, callback) {
      if (changedCriteria && this.projectListTable) {
        this.projectListTable.offset = 0;
      }

      var searchCriteriaTemp = {
        criteria: {},
        facet_filters: this.matchmakerFacetCriteria,
        limit: this.projectListTable ? this.projectListTable.limit : 100,
        offset: this.projectListTable ? this.projectListTable.offset : 0
        // sort_field: '',
        // sort_order: ''
      },
        parsedCriteria,
        criteria,
        searchParam;

      searchParam = {
        title: '',
        text: this.searchCriteria['matchmaker_text'],
        include_active_projects: true
        // text: 'this is a test Warburg Effect',
        // fiscal_years: '',
        // agencies: ''
      };

      if (!changedCriteria && this.$route.params.search_id) {
        searchCriteriaTemp['search_id'] = this.$route.params.search_id;
        searchCriteriaTemp['cache_id'] = this.$store.matchMakerResults ? this.$store.matchMakerResults.meta.properties.CacheId : null

      } else {
        if (!changedCriteria && this.$refs.facets && this.$refs.facets.$refs.facets) {
          searchParam.include_active_projects = this.$refs.facets.$refs.facets.facetCriteria.is_active_projects;
        }
        searchCriteriaTemp.criteria = searchParam;
      }

      window.getSearchFacets('Matchmaker', searchCriteriaTemp, callback, this.$route.name);
    },
    getPublicationsFacets (changedCriteria, callback) {
      var parsedCriteria = window.parseCriteria(this.searchCriteria, { isSubmitForm: false }),
        criteria = window.stringifySearchCriteriaFields(parsedCriteria),
        searchParam = {
          facet_filters: this.searchFacetCriteria,
          criteria: criteria,
          is_shared: this.$route.query['shared'] ? true : false,
          Cache_Id: this.$store.state.publicationResults ? this.$store.state.publicationResults.meta.properties.CacheId : null,
          After_Key: this.$store.state.publicationResults ? this.$store.state.publicationResults.meta.properties.AfterKey : null
        };

      this.countLoader = true;
      if (!changedCriteria && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }

      window.getPublicationsFacets(searchParam, callback);
    },
    getProjectSearchGraphData (changedCriteria, callback) {
      var parsedCriteria = window.parseCriteria(this.searchCriteria, { isSubmitForm: false }),
        criteria = window.stringifySearchCriteriaFields(parsedCriteria),
        searchParam = {
          facet_filters: this.searchFacetCriteria,
          summarized_by: this.graphSettings.summaryBy.value,
          plotted_by: this.graphSettings.plotBy,
          limit_count: 100,
          criteria: criteria,
          is_shared: this.$route.query['shared'] ? true : false
        };

      if (!changedCriteria && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }
      // if (this.sortField) {
      //     searchParam.sort_field = this.sortField;
      //     searchParam.sort_order = this.sortOrder
      // }

      if (this.$refs.Charts) {
        this.$refs.Charts.loadingGraphData = true;
      }

      if (callback) {
        window.getSearchResults(searchParam, callback);
      } else {
        window.getSearchGraphData(searchParam, this.handleGraphData);
      }
    },
    handleGraphData (data) {
      var totalFunding = 0,
        subprojectCount = 0,
        projectCount = 0,
        totalSubProjectFunding = 0,
        footerDataObj,
        url = '';

      if (this.$refs.Charts) {
        this.$refs.Charts.loadingGraphData = false;
      }

      if (!data || (data && data.status && data.status !== 200)) {
        this.setGraphData(null);
        this.serviceError = this.externalURLs.reporter_project_search_error_message;
        return false;
      } else {
        if (data && data.meta && data.meta.search_id !== this.$route.params.search_id) {
          url = '/search/' + data.meta.search_id + '/projects/charts';//?searchMode=' + this.searchType;

          this.$router.push(url);
        }
        this.setSearchMeta(data.meta);
        for (let i = 0; i < data.results.length; i++) {
          totalFunding += data.results[i].project_funding_amt;
          projectCount += data.results[i].project_count;
          totalSubProjectFunding += data.results[i].sub_project_funding_amt;
          subprojectCount += data.results[i].subproject_count;
        }

        footerDataObj = {
          'group_by': 'Total',
          project_funding_amt: totalFunding,
          project_count: projectCount,
          sub_project_funding_amt: totalSubProjectFunding,
          subproject_count: subprojectCount,
        };
        if (this.$refs.Charts && this.$refs.Charts.summaryBy.value === 'FundingIC') {
          footerDataObj.project_count = '(*)',
            data.footerNote = "(*) Because projects can be co-funded by more than one institute or center, the sum of the Projects column in this table may exceed the actual number of projects.";
        }
        data.footerData = [];
        data.footerData.push(footerDataObj);
        this.setGraphData(data);
        this.$refs.Charts.initChart();
      }
    },
    getProjectsForCharts (callback) {
      var parsedCriteria = window.parseCriteria(this.chartProjectsSearchCriteria, { isSubmitForm: false }),
        criteria = window.stringifySearchCriteriaFields(parsedCriteria),
        searchParam = {
          offset: this.$refs.Charts.drillDownTable.offset,
          limit: this.$refs.Charts.drillDownTable.limit,
          facet_filters: this.chartProjectsFilterBy,
          criteria: criteria,
          is_shared: this.$route.query['shared'] ? true : false
        };

      if (this.$refs.Charts.drillDownTable.sortField) {
        searchParam.sort_field = this.$refs.Charts.drillDownTable.sortField;
        searchParam.sort_order = this.$refs.Charts.drillDownTable.sortOrder
      }

      this.$refs.Charts.drillDownTable.isLoading = true;
      window.getSearchResults(searchParam, callback);
    },
    getProjectSearchCirclesData (changedCriteria) {
      var parsedCriteria = window.parseCriteria(this.searchCriteria, { isSubmitForm: false }),
        criteria = window.stringifySearchCriteriaFields(parsedCriteria),
        searchParam = {
          facet_filters: this.searchFacetCriteria,
          limit_count: 100,
          criteria: criteria,
          is_shared: this.$route.query['shared'] ? true : false
        };

      if (!changedCriteria && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }

      if (this.$refs.Charts) {
        this.$refs.Charts.loadingCirclesData = true;
      }

      this.serviceError = null;
      window.getSearchCirclesData(searchParam, this.handleCirclesData);
    },
    handleCirclesData (data) {
      // var totalFunding = 0,
      //     subprojectCount = 0,
      //     projectCount = 0,
      //     totalSubProjectFunding = 0,
      //     footerDataObj,
      //     url = '';

      if (this.$refs.Charts) {
        this.$refs.Charts.loadingCirclesData = false;
      }

      if (!data || (data && data.status && data.status !== 200)) {
        this.setCirclesData(null);
        this.serviceError = this.externalURLs.reporter_project_search_error_message;
        return false;
      } else {
        this.setCirclesData(data);
        if (data && data.clusters &&  data.clusters.length > 0) {
          this.$refs.Charts.initGraph();
        }
      }
    },
    getProjectsForCircles (callback) {
      var searchParam = {
        offset: this.$refs.Charts.circlesTable.offset,
        limit: this.$refs.Charts.circlesTable.limit,
        facet_filters: null,
        criteria: {
          appl_ids: this.$refs.Charts.selectedApplIds,
          fiscal_years: this.searchType === "Smart" || this.$store.state.searchCriteria.is_smart_search
            ? this.getAllFiscalYears() : this.getFYListFromCriteria(),
          is_agency_admin: true,
          matchmaker_field: "project"
        },
        is_shared: this.$route.query['shared'] ? true : false
      };

      if (this.$refs.Charts.circlesTable.sortField) {
        searchParam.sort_field = this.$refs.Charts.circlesTable.sortField;
        searchParam.sort_order = this.$refs.Charts.circlesTable.sortOrder
      }

      this.$refs.Charts.circlesTable.isLoading = true;
      window.getSearchResults(searchParam, callback);
    },
    getProjectSearchMapData (changedCriteria, callback) {
      let criteria = this.getNewSearchCriteria(changedCriteria),
        searchParam;

      if (changedCriteria && this.projectListTable) {
        this.projectListTable.offset = 0;
      }

      searchParam = {
        offset: this.projectListTable ? this.projectListTable.offset : 0,
        limit: this.projectListTable ? this.projectListTable.limit : 100,
        facet_filters: this.searchFacetCriteria,
        criteria: criteria,
        is_shared: this.$route.query['shared'] ? true : false
      };

      if (!changedCriteria && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }

      // if (this.$refs.Charts && (this.$refs.Charts.loadingDrillDownData || this.$refs.Charts.loadSlide)) {
      //     searchParam.criteria = this.chartProjectsSearchCriteria;
      //     searchParam.facet_filters = this.chartProjectsFilterBy;
      // }

      if (this.projectListTable && this.projectListTable.sortField) {
        searchParam.sort_field = this.projectListTable.sortField;
        searchParam.sort_order = this.projectListTable.sortOrder
      }

      if (this.projectListTable) {
        this.projectListTable.isLoading = true;
      }
      if (this.$refs && this.$refs.searchCriteria) {
        this.$refs.searchCriteria.showCriteria = false;
      }

      if (callback) {
        window.getSearchResults(searchParam, callback);
      } else {
        window.getSearchResults(searchParam, this.updateProjectSearchResults);
      }

    },
    getPublicationResults (changedCriteria) {
      if (changedCriteria) {
        this.publicationTable.offset = 0;
        this.countLoader = true;
      }
      var parsedCriteria = window.parseCriteria(this.searchCriteria, { isSubmitForm: false }),
        criteria = window.stringifySearchCriteriaFields(parsedCriteria),
        searchParam = {
          offset: this.publicationTable.offset,
          limit: this.publicationTable.limit,
          facet_filters: this.searchFacetCriteria,
          criteria: criteria,
          is_shared: this.$route.query['shared'] ? true : false,
          CoreProjSortField: 'fiscal_year',           // hardcoded to fetch top 10000 publications
          CoreProjSortOrder: 'desc',
          Cache_Id: this.$store.state.publicationResults ? this.$store.state.publicationResults.meta.properties.CacheId : null
        };

      if (!changedCriteria && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }

      if (this.publicationTable.sortField && this.publicationTable.sortField !== '') {
        searchParam.sort_field = this.publicationTable.sortField;
        searchParam.sort_order = this.publicationTable.sortOrder
      }

      this.publicationTable.isLoading = true;
      if (this.$refs && this.$refs.searchCriteria) {
        this.$refs.searchCriteria.showCriteria = false;
      }
	  
	  gtag('event','Click', {
			'event_category': 'Publication Results',
			'event_label': 'Publication Results (page# ' + (this.publicationTable.offset === 0 ? 0 : this.publicationTable.offset / this.publicationTable.offset) + ')',
			'value': 'Publication Results (page# ' + (this.publicationTable.offset === 0 ? 0 : this.publicationTable.offset / this.publicationTable.offset) + ')'
		});
      window.getPublicationsResults(searchParam, this.updatePublicationsResults);
    },
    updatePublicationsResults (data) {
      this.publicationTable.isLoading = false;
      this.publicationTable.loadingMoreData = false;
      // if (data.meta && data.results) {

      if (!data || (data && data.status && data.status !== 200)) {
        this.setPublicationResults(null);
        this.countLoader = false;
        this.serviceError = this.externalURLs.reporter_project_search_error_message;
        return false;
      } else {
        if (data) {
          data.meta.sort_field = this.publicationTable.sortField;
          data.meta.sort_order = this.publicationTable.sortOrder;
          // this.setPublicationResults(data);
          // if (data.meta.properties && data.meta.properties.Shared_Facet) {
          //     this.updateSearchResultsFacets(data.meta.properties.Shared_Facet);
          // }
          if (this.publicationTable.offset === 0) {
            this.setPublicationResults(data);
          } else {
            this.updatePublicationResults(data);
          }
          if (this.$refs && this.$refs.dataTable) {
            this.$refs.dataTable.initTableData();
          }
          setTimeout(() => {
            if (data.results.length > 0) {
              window.fixHeaders('scrollTable');
            }
          }, 10);
        }
      }
    },
    logPublicationsPage () {
    gtag('event','Click', {
	  'event_category': 'Publication Results',
	  'event_label': 'Publication Page Access Log',
	   'value': 'Publication Page Access Log'
	});	
      window.logPublicationsPage();
    },
    setPublicationSortDetails (selectedSortColumn, tableName, callback) {
      var selectedSortField = this.getFieldNameFromSettings(selectedSortColumn),
        facetToQueryStr = {};

      if (this[tableName].sortField === selectedSortField) {
        this[tableName].sortOrder = (this[tableName].sortOrder === 'asc' ? 'desc' : 'asc');
      } else {
        this[tableName].sortOrder = selectedSortColumn.sortOrder;
      }
      this[tableName].sortField = selectedSortField;
      this[tableName].offset = 0;
      // this.$router.push({ query: { 'sort_field': this[tableName].sortField, 'sort_order': this[tableName].sortOrder }});
      if (this.$refs && this.$refs.facets)
        facetToQueryStr = this.$refs.facets.generateQueryString();
      
      facetToQueryStr['sort_field'] = this[tableName].sortField;
      facetToQueryStr['sort_order'] = this[tableName].sortOrder;

      if (tableName !== 'publicationResultsTable') {
        this.$router.push({ query: facetToQueryStr });
      }

	  gtag('event','Click', {
		'event_category': 'Publication Results',
		'event_label': 'Publication Results - sorted on ' + this[tableName].sortField + ' (' + this[tableName].sortOrder + ')',
		'value': 'Publication Results - sorted on ' + this[tableName].sortField + ' (' + this[tableName].sortOrder + ')'
	  });		
      
      callback(false);
      //future function
    },
    getPatents (changedCriteria, callback) {
      if (changedCriteria && this.patentTable) {
        this.patentTable.offset = 0;
        this.countLoader = true;
      }
      var tempSearchCriteria = copyData(this.searchCriteria),
        parsedCriteria,
        criteria,
        searchParam;

      parsedCriteria = window.parseCriteria(tempSearchCriteria, { isSubmitForm: false }),
        criteria = window.stringifySearchCriteriaFields(parsedCriteria),
        searchParam = {
          offset: this.patentTable.offset,
          limit: this.patentTable.limit,
          facet_filters: this.searchFacetCriteria,
          criteria: criteria,
          Cache_Id: this.$store.state.patentResults ? this.$store.state.patentResults.meta.properties.CacheId : null,
          is_shared: this.$route.query['shared'] ? true : false
        };

      if (!changedCriteria && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }

      if (this.patentTable && this.patentTable.sortField) {
        searchParam.sort_field = this.patentTable.sortField;
        searchParam.sort_order = this.patentTable.sortOrder;
      }

      this.patentTable.isLoading = true;
      if (this.$refs && this.$refs.searchCriteria) {
        this.$refs.searchCriteria.showCriteria = false;
      }
      window.getPatentResults(searchParam, this.updatePatents);
    },
    updatePatents (data) {
      this.patentTable.isLoading = false;
      this.patentTable.loadingMoreData = false;

      if (!data || (data && data.status && data.status !== 200)) {
        this.setPatentResults(null);
        this.serviceError = this.externalURLs.reporter_project_search_error_message;
        return false;
      } else {
        if (data) {
          data.meta.sort_field = this.patentTable.sortField;
          data.meta.sort_order = this.patentTable.sortOrder;
          if (this.patentTable.offset === 0) {
            this.setPatentResults(data);
          } else {
            this.updatePatentResults(data);
          }
          if (this.$refs && this.$refs.dataTable) {
            this.$refs.dataTable.initTableData();
          }
          setTimeout(() => {
            if (data.results.length > 0) {
              window.fixHeaders('scrollTable');
            }
          }, 10);
        }
      }
    },
    getClinicalStudies (changedCriteria, callback) {
      if (changedCriteria && this.clinicalStudyTable) {
        this.clinicalStudyTable.offset = 0;
      }
      var tempSearchCriteria = copyData(this.searchCriteria),
        parsedCriteria,
        criteria,
        searchParam;

      parsedCriteria = window.parseCriteria(tempSearchCriteria, { isSubmitForm: false }),
        criteria = window.stringifySearchCriteriaFields(parsedCriteria),
        searchParam = {
          offset: this.clinicalStudyTable ? this.clinicalStudyTable.offset : 0,
          limit: this.clinicalStudyTable ? this.clinicalStudyTable.limit : 100,
          facet_filters: this.searchFacetCriteria,
          criteria: criteria,
          Cache_Id: this.$store.state.clinicalStudyResults ? this.$store.state.clinicalStudyResults.meta.properties.CacheId : null,
          is_shared: this.$route.query['shared'] ? true : false
        };

      if (!changedCriteria && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }

      if (this.clinicalStudyTable && this.clinicalStudyTable.sortField) {
        searchParam.sort_field = this.clinicalStudyTable.sortField;
        searchParam.sort_order = this.clinicalStudyTable.sortOrder;
      }

      if (this.clinicalStudyTable) {
        this.clinicalStudyTable.isLoading = true;
      }
      if (this.$refs && this.$refs.searchCriteria) {
        this.$refs.searchCriteria.showCriteria = false;
      }
      window.getClinicalStudyResults(searchParam, this.updateClinicalResults);
    },
    updateClinicalResults (data) {
      if (this.clinicalStudyTable) {
        this.clinicalStudyTable.isLoading = false;
        this.clinicalStudyTable.loadingMoreData = false;
      }

      if (!data || (data && data.status && data.status !== 200)) {
        this.setClinicalStudyResults(null);
        this.serviceError = this.externalURLs.reporter_project_search_error_message;
        return false;
      } else {
        if (data) {
          data.meta.sort_field = this.clinicalStudyTable.sortField;
          data.meta.sort_order = this.clinicalStudyTable.sortOrder;
          if (this.clinicalStudyTable.offset === 0) {
            this.setClinicalStudyResults(data);
          } else {
            this.updateClinicalStudyResults(data);
          }
          if (this.$refs && this.$refs.dataTable) {
            this.$refs.dataTable.initTableData();
          }
          setTimeout(() => {
            if (data.results.length > 0) {
              window.fixHeaders('scrollTable');
            }
          }, 10);
        }
      }
    },
    getNewsData (newsType, callback) {
      var curTableName = newsType + 'Table',
        curTableRefName = newsType + 'DataTable',
        searchParam = {
          offset: this[curTableName].offset,
          limit: this[curTableName].limit,
          facet_filters: this.searchFacetCriteria,
          is_shared: this.$route.query['shared'] ? true : false,
          newsType: newsType,
          searchid: this.$route.params.search_id,
          sort_field: this[curTableName] ? this[curTableName].sortField : null,
          sort_order: this[curTableName] ? this[curTableName].sortOrder : null
        };

      if (this.newsResults[newsType] && this.newsResults[newsType].meta && this.newsResults[newsType].meta.properties) {
        searchParam.last_item = this.newsResults[newsType].meta.properties.lastItem;
      }
      if (this.newsResults[newsType] && this.newsResults[newsType].meta && this.newsResults[newsType].meta.properties) {
        searchParam.Cache_Id = this.newsResults[newsType].meta.properties.CacheId;
      }
      if (this.$refs && this.$refs.searchCriteria) {
        this.$refs.searchCriteria.showCriteria = false;
      }
      window.getNewsResultsByType(searchParam, newsType, callback);
    },
    getNewsCount (newsType, callback) {
      var curTableName = newsType + 'Table',
        curTableRefName = newsType + 'DataTable',
        searchParam = {
          offset: this[curTableName].offset,
          newsType: newsType,
          facet_filters: this.searchFacetCriteria,
          searchid: this.$route.params.search_id,
        };

      if (this.newsResults[newsType] && this.newsResults[newsType].meta && this.newsResults[newsType].meta.properties) {
        searchParam.Cache_Id = this.newsResults[newsType].meta.properties.CacheId;
        searchParam.last_item = this.newsResults[newsType].meta.properties.lastItem;
      }
      if (this.newsCount[newsType] && this.newsCount[newsType].lastItem) {
        searchParam.last_item = this.newsCount[newsType].lastItem;
      }
      window.getNewsCountByType(searchParam, newsType, callback);
    },
    getProjectsFromChart (callback, filterBy) {
      var parsedCriteria = window.parseCriteria(this.searchCriteria, { isSubmitForm: false }),
        criteria = window.stringifySearchCriteriaFields(parsedCriteria),
        searchParam = {
          offset: this.chartTable.offset,
          limit: this.chartTable.limit,
          facet_filters: filterBy,
          criteria: criteria,
          search_id: this.$route.params.search_id,
          is_shared: this.$route.query['shared'] ? true : false
        };

      if (this.chartTable.sortField) {
        searchParam.sort_field = this.chartTable.sortField;
        searchParam.sort_order = this.chartTable.sortOrder
      }

      window.getSearchResults(searchParam, callback);
    },
    getProjectInfo (project_id, requestedSection, callback) {
      var searchParam = {
        "project_id": project_id
      };

      if (this.$route.params && this.$route.params.search_id) {
        searchParam['search_id'] = this.$route.params.search_id;
      }
      switch (requestedSection) {
        case 'projectHistory':
          searchParam = {
            "project_id": project_id,
            sort_field: this.detailsHistoryTable.sortField,
            sort_order: this.detailsHistoryTable.sortOrder,
            offset: this.detailsHistoryTable.offset
          }
          break;
        case 'subProjects':
          searchParam = {
            "project_id": project_id,
            sort_field: this.detailsSubProjectsTable.sortField,
            sort_order: this.detailsSubProjectsTable.sortOrder,
            offset: this.detailsSubProjectsTable.offset
          }
          break;
        case 'similarProjects':
          searchParam = {
            "project_id": project_id,
            sort_field: this.detailsSimilarProjectsTable.sortField,
            sort_order: this.detailsSimilarProjectsTable.sortOrder,
            offset: this.detailsSimilarProjectsTable.offset
          }
          break;
        case 'PressRelease':
        case 'ResearchMatters':
        case 'AIRS':
        case 'ARRA':
        case 'AIMRS':
          searchParam = {
            "project_id": this.$route.params.appl_id,
            "Newstype": requestedSection,
            "grant_Num_Or_Full_Proj_Num": project_id,
            "limit": this['details' + requestedSection + 'Table'].limit
          }
          break;
      }
      gtag('event','Click', {
		'event_category': 'Project Details',
		'event_label': 'Project Details (' + requestedSection + ') for - ' + project_id,
		'value': 'Project Details (' + requestedSection + ') for - ' + project_id
	  });	
      window.getProjectBasicInfo(searchParam, requestedSection, callback);
    },
    getExportFields () {
      window.getExportFields(this.updateExportFields);
    },
    updateExportFields (data) {
      if (this.$refs && this.$refs.exportModule) {
        this.$refs.exportModule.loadExportFields = false;
      }
      this.setExportFields(data);
    },
    getApplId (coreProjNum, callback) {

	   gtag('event','Click', {
		'event_category': 'Publications Hitlist',
		'event_label': 'Appl Id for core project number: ' + coreProjNum,
		'value': 'Appl Id for core project number: ' + coreProjNum
	  });	
      window.findApplId(coreProjNum, callback);
    },
    getExportFile (searchParam, callback, exportSectionName) {
      var self = this,
        analyticsLabel = this.$route.name === 'list' ? 'search results' : this.$route.name,
        exportSectionName = exportSectionName ? exportSectionName : this.$route.name;

	  gtag('event','Click', {
		'event_category': 'Export',
		'event_label': analyticsLabel + '(search id: ' + this.$route.params.search_id + ')',
		'value': analyticsLabel + '(search id: ' + this.$route.params.search_id + ')'
	  });	
      window.getExportFileName(exportSectionName, searchParam, callback);
    },
    getExportStatus (exportId, callback) {
      window.getExportStatus(this.currentExportTab, exportId, callback);
    },
    getProjectsForPersonSummary (callback) {
      var searchCriteriaTemp = {
        fiscal_years: "ap",
        pi_profile_ids: this.$route.params.pi_id,
        po_names: this.$route.params.po_name
      },
        parsedCriteria,
        criteria,
        searchParam;

      parsedCriteria = window.parseCriteria(searchCriteriaTemp, { isSubmitForm: false });
      criteria = window.stringifySearchCriteriaFields(parsedCriteria);

      searchParam = {
        offset: 0,
        limit: 100,
        facet_filters: null,
        criteria: criteria,
      };

      if (callback) {
        window.getSearchResults(searchParam, callback);
      } else {
        window.getSearchResults(searchParam, this.handlePIProjects);
      }
    },
    handlePIProjects (data) {
      var id,
        myWindow;

      this.$router.push({ path: '/search/' + data.meta.search_id + '/projects' });
      this.$nextTick(() => {
        this.setPersonSummaryProjects(data);
        if (this.$refs && this.$refs.dataTable) {
          this.$refs.dataTable.initTableData();
        }
      });
    },
    getProjectsForReportSmartSearch (callback) {
      var searchCriteriaTemp = {
        fiscal_years: "ap",
        smart_search_text: this.$route.params.queryString
      },
        parsedCriteria,
        criteria,
        searchParam;

      parsedCriteria = window.parseCriteria(searchCriteriaTemp, { isSubmitForm: false });
      criteria = window.stringifySearchCriteriaFields(parsedCriteria);

      searchParam = {
        offset: 0,
        limit: 100,
        facet_filters: null,
        criteria: criteria,
      };

      if (callback) {
        window.getSearchResults(searchParam, callback);
      }
    },
    clearSearchResults (options) {
      if (options.resetFacets) {
        if (this.$refs.facets) {
          this.$refs.facets.resetFacets();
        } else {
          this.setSearchFacetCriteria(null);
          this.setSearchFacetData(null);
          this.setPublicationsFacetData(null);
          this.setPublicationsFacetCriteria(null);
          this.setMatchmakerFacetData(null);
          this.setMatchmakerFacetCriteria(null);
        }
      }
      this.setPatentResults(null);
      this.setClinicalStudyResults(null);
      this.setPublicationResults(null);
      this.setCoreProjCountPub(null);
      this.setSearchResults(null);
      this.setMatchmakerResults(null);
      this.setMatchmakerPOResults(null);
      this.setGraphData(null);
      this.setCirclesData(null);
      this.resetNewsResults(null);
    },
    parseFacetFiltersFromURL () {
      var filtersArr = this.$route.query ? this.$route.query : [], //.filters ? this.$route.query.filters.split('$') : [],
        facetCriteria = this.$store.state.searchFacetCriteria ? this.$store.state.searchFacetCriteria : {},
        valArr;

      for (let key in filtersArr) {
        if (filtersArr[key]) {
          valArr = filtersArr[key].split(';');
          switch (key) {
            case 'fy':
              facetCriteria['fiscal_years'] = valArr;
              break;
            case 'org':
              facetCriteria['org_names'] = valArr;
              break;
            case 'PI':
              valArr = decodeURI(filtersArr[key]).split(';');
              facetCriteria['principal_investigators'] = valArr;
              break;
            case 'projects':
              facetCriteria['is_active_projects'] = true;
              break;
            case 'searchMode':
              break;
            default:
              facetCriteria[key] = valArr;
          }
        }
      }

      // for (let i = 0; i < filtersArr.length; i++) {
      //     if (filtersArr[i].trim() !== '') {
      //         valArr = filtersArr[i].split(';');
      //         switch (valArr[0]) {
      //             case 'fy':
      //                 facetCriteria['fiscal_years']  = valArr.slice(1);
      //                 break;
      //             case 'org':
      //                 facetCriteria['org_names']  = valArr.slice(1);
      //                 break;
      //             case 'PI':
      //                 facetCriteria['principal_investigators']  = valArr.slice(1);
      //                 break;
      //             case 'projects':
      //                 facetCriteria['is_active_projects']  = true;
      //                 break;
      //             default:
      //                 facetCriteria[valArr[0]]  = valArr.slice(1);
      //         }
      //     }
      // }
      this.updateSearchResultsFacets(facetCriteria);
      return facetCriteria;
    },
    saveSharedSearchID (shared_as) {
      var controllerArr = this.$route.path.split('/'),
        searchParam = {
          search_id: this.$route.params.search_id,
          shared_as: shared_as,
          controller: controllerArr && controllerArr.length > 0 ? controllerArr[controllerArr.length - 1] : '',
          url: location.href,
        };

      window.saveSharedURL(searchParam);
    },
    getAllFiscalYears () {
      var fyList = [],
        fyLookUp = this.$store.state.lookup.fiscal_years;

      for (let i = 0; i < fyLookUp.length; i++) {
        fyList.push(fyLookUp[i].value);
      }
      return fyList.join(";");
    },
    getFYListFromCriteria () {
      var criteriaTemp = this.$store.state.searchCriteria,
        fyList = [];

      if (criteriaTemp && criteriaTemp.fiscal_years) {
        if (criteriaTemp.fiscal_years && typeof criteriaTemp.fiscal_years[0] === "object") {
          fyList = criteriaTemp.fiscal_years.map(fy => {
            return fy.value;
          });
          return fyList.join(';')
        }
        if (criteriaTemp.fiscal_years && criteriaTemp.fiscal_years instanceof Array && criteriaTemp.fiscal_years.length > 0) {
          return criteriaTemp.fiscal_years.join(";");
        }
        return criteriaTemp.fiscal_years.join(";");
      }
      return "ap";
    },
    buildURLWithParams (url, params) {
      if (url && params) {
        for (var i = 0; i < params.length; i++) {
          url = url.replace('{{' + i + '}}', params[0]);
        }
        return url;
      }
      return '';
    }
  }
}
